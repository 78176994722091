@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    color: #090a0b;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857142857143;
    font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu',
      'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }

  table {
    width: 100%;
  }

  a {
    color: #0052cc;
    text-decoration: none;

    &:hover {
      color: #0065ff;
    }
  }

  svg {
    display: inline-block;
  }

  h1 {
    @apply text-2xl;
  }

  h2 {
    @apply text-xl;
  }

  h3 {
    @apply text-lg;
  }
}

.no-decoration {
  text-decoration: none !important;
}

// TODO: move this style to royan package
.drawer {
  width: 100%;
  padding-top: 6px;
  padding-right: 64px;
}

.removeAtlaskitTableBorder {
  table {
    border-bottom: none;
  }

  th {
    border: none;
  }
}

.atlaskit-portal {
  z-index: 1100 !important;
}

.atlaskit-portal:first-child {
  z-index: 1200 !important;
}

.removeWidthLimitFromAtlaskitTag {
  > span > span {
    max-width: initial;
  }
}

.removeAtlaskitTableBorder {
  table {
    border-bottom: none;
  }

  th {
    border: none;
  }
}

.removeMarginFromAtlaskitTagWrapper {
  > div > span {
    margin-left: 0;
  }
}

.borderOfAtlaskitTableWithTotalRow {
  table {
    border-bottom: 0;
  }

  table tr:nth-last-child(2) td {
    border-bottom: 2px solid #dfe1e6;
  }

  table tr:last-child td {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.borderOfAtlaskitTableWithTowTotalRow {
  table {
    border-bottom: 0;
    td {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  table tr:nth-last-child(3) td {
    border-bottom: 2px solid #dfe1e6;
  }
}

.shipmentFinanceAtlaskitTable {
  table {
    border-bottom: 0;
    tr {
      td {
        border-bottom: 1px solid #dfe1e6;
        &:nth-last-child(2),
        &:nth-last-child(1) {
          padding: 10px 8px;
          vertical-align: top;
          height: 100%;
          color: #fff;
          width: 106px !important;
        }
        &:nth-last-child(2) {
          background-color: #ff5630;
        }
        &:nth-last-child(1) {
          background-color: #36b37e;
        }
      }
      &[data-ts--dynamic-table--table-row--highlighted='true'] {
        background-color: #091e4208 !important;
      }
      &:nth-last-child(1) td {
        border-bottom: 0;
      }
      &:nth-last-child(2) {
        td {
          border-bottom: 0;
          &:nth-last-child(1),
          &:nth-last-child(2) {
            border-bottom: 1px solid #dfe1e6;
          }
        }
      }
    }
  }
}

.consolidationFinanceAtlaskitTable {
  table {
    border-bottom: 0;
    tr {
      td {
        border-bottom: 1px solid #dfe1e6;
      }
      td:nth-last-child(5),
      td:nth-last-child(4),
      td:nth-last-child(3),
      td:nth-last-child(2),
      td:nth-last-child(1) {
        padding: 10px 8px;
        height: 100%;
        color: #fff;
        width: 106px !important;
      }
      td:nth-last-child(5) {
        background-color: #ff991f;
      }
      td:nth-last-child(4) {
        background-color: #ff5630;
      }
      td:nth-last-child(3) {
        background-color: #de350b;
      }
      td:nth-last-child(2) {
        background-color: #36b37e;
      }
      td:nth-last-child(1) {
        background-color: #57d9a3;
      }
      &.negative-margin {
        td:nth-last-child(1) {
          background-color: #ff8f73;
        }
      }
      &:nth-last-child(2) td {
        border-width: 2px;
      }
      &:nth-last-child(1) td {
        border-bottom: 0;
        border-radius: 0 0 3px 3px;
        // &:nth-last-child(1) {
        //   background-color: transparent;
        // }
      }
    }
  }
}

.dynamicTableHighlighted {
  table {
    tr[data-ts--dynamic-table--table-row--highlighted='true'] {
      background-color: #091e4208 !important;
    }
  }
}

.lessPaddingAtlaskitTable {
  table td {
    padding: 4px;
  }
}

.editFinanceAtlaskitTable {
  table tr:hover {
    background-color: transparent !important;
  }

  table tr td:nth-last-child(4),
  table tr td:nth-last-child(3) {
    vertical-align: top;
    height: 100%;
    color: #fff;
  }
  table tr:first-child {
    td {
      border-bottom: 2px solid #dfe1e6;
      padding-bottom: 14px;

      &:first-child,
      &:nth-last-child(4),
      &:nth-last-child(3) {
        border-bottom: 0;
      }
    }
  }
  table tr:nth-child(2) {
    td {
      padding-top: 14px;
    }
  }
  table tr td:nth-last-child(4) {
    background-color: #ff5630;
  }
  table tr td:nth-last-child(3) {
    background-color: #36b37e;
  }
  table tr:first-child {
    td:nth-last-child(4),
    td:nth-last-child(3) {
      border-radius: 5px 5px 0 0;
    }
  }
  table tr:last-child {
    td:nth-last-child(4),
    td:nth-last-child(3) {
      border-radius: 0 0 5px 5px;
    }
  }
}

.LoadingOverlay {
  min-height: 100vh;
}

.qr-video-wrapper > div {
  width: 100%;
  padding-top: 140% !important;
  margin: 32px 0 20px !important;
}

.scanner {
  #html5qr-code-full-region__scan_region {
    min-height: 250px !important;
  }

  #html5qr-code-full-region {
    border: 1px solid #c6cbd3 !important;
    margin: 25px 0 10px !important;
    font-family: inherit !important;
    color: inherit !important;
    border-radius: 3px;
    & > div > img {
      display: none !important;
    }
  }

  #html5-qrcode-button-camera-stop,
  #html5-qrcode-anchor-scan-type-change {
    display: none !important;
  }

  #html5-qrcode-button-camera-start {
    background: #f4f5f7;
    padding: 5px 15px;
    border-radius: 3px;
    margin: 5px;
    color: #344563;
  }

  #html5-qrcode-select-camera {
    background: #f4f5f7;
    padding: 5px 15px;
    border-radius: 3px;
    margin: 5px auto;
    color: #344563;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.ck-editor .ck-toolbar {
  border: 0 !important;
}

.ck-editor .ck-content {
  border: 0 !important;
  border-top: 2px solid rgba(9, 30, 66, 0.04) !important;
  min-height: 200px !important;
}

.ck-dropdown__arrow {
  z-index: 0 !important;
}

.tableTreeCellAtlassian button {
  margin-top: 5px;
}

@import 'assets/styles/panel/main';
